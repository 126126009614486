import NavItem from "./NavItem";
import MenuLinks from "./config";
import Logo from "src/components/Logo";
import React, { useEffect } from "react";
import MyAvatar from "src/components/MyAvatar";
import Scrollbars from "src/components/Scrollbars";
import { PATH_APP } from "src/routes/paths";
import { Link as RouterLink, useLocation, matchPath } from "react-router-dom";
import { alpha, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Link,
  List,
  Drawer,
  Hidden,
  Typography,
  ListSubheader,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Image } from "src/components/Image";

const DRAWER_WIDTH = 280;

const useStyles = makeStyles((theme) => {
  const isLight = theme.palette.mode === "light";

  return {
    drawer: {
      [theme.breakpoints.up("lg")]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
      },
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
      background: theme.palette.background.default,
    },
    subHeader: {
      ...theme.typography.overline,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    account: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(2, 2.5),
      margin: theme.spacing(1, 2.5, 5),
      // @ts-ignore
      borderRadius: theme.shape.borderRadiusSm,
      background: theme.palette.grey[isLight ? 200 : 800],
    },
    doc: {
      padding: theme.spacing(2.5),
      // @ts-ignore
      borderRadius: theme.shape.borderRadiusMd,
      backgroundColor: isLight
        ? alpha(theme.palette.primary.main, 0.08)
        : // @ts-ignore
          theme.palette.primary.lighter,
    },
    imagesLogo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    nikiLogo: {
      marginRight: 5,
    },
    companyLogo: {
      height: 30,
    }
  };
});

export type reduceChildProps = {
  array?: any;
  item?: any;
  pathname?: any;
  level?: any;
  t?: any;
};

export type renderNavItemsProps = {
  items?: any;
  pathname?: any;
  level?: number;
  t?: any;
};

export type NavBarProps = {
  isOpenNav: boolean;
  onCloseNav: () => void;
};

function reduceChild({ array, item, pathname, level, t }: reduceChildProps) {
  const key = item.href + level;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={t(`navBar.${item.title}`)}
        open={Boolean(match)}
      >
        {renderNavItems({
          pathname,
          level: level + 1,
          items: item.items,
          t,
        })}
      </NavItem>,
    ];
  } else {
    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        href={item.href}
        icon={item.icon}
        info={item.info}
        title={t(`navBar.${item.title}`)}
      />,
    ];
  }
  return array;
}

function renderNavItems({
  items,
  pathname,
  level = 0,
  t,
}: renderNavItemsProps) {
  return (
    <List disablePadding>
      {items.reduce(
        (array: any, item: any) =>
          reduceChild({ array, item, pathname, level, t }),
        []
      )}
    </List>
  );
}

function NavBar({ isOpenNav, onCloseNav }: NavBarProps) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { firstName } = useSelector((state: any) => state.user);
  const { name, logoUrl } = useSelector((state: any) => state.company);
  const companyName = name
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpenNav && onCloseNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbars>
      <Box sx={{ px: 2.5, py: 3 }} className={classes.imagesLogo} >
        
        {logoUrl
          ? <RouterLink to="/">
              <Image src={logoUrl} alt={`${companyName} logo`} className={classes.companyLogo} />
            </RouterLink>

          : <RouterLink to="/">
              <Logo className={classes.nikiLogo} />
            </RouterLink>
        }
      </Box>

      <Link
        underline="none"
        component={RouterLink}
        to={PATH_APP.management.root}
      >
        <div className={classes.account}>
          <MyAvatar />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
              {firstName}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {companyName}
            </Typography>
          </Box>
        </div>
      </Link>

      {MenuLinks.map((list) => (
        <List
          disablePadding
          key={list.subheader}
          subheader={
            <ListSubheader
              disableSticky
              disableGutters
              className={classes.subHeader}
            >
              {t(`navBar.${list.subheader}`)}
            </ListSubheader>
          }
        >
          {renderNavItems({
            items: list.items,
            pathname: pathname,
            t,
          })}
        </List>
      ))}
    </Scrollbars>
  );

  return (
    <nav className={classes.drawer}>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={isOpenNav}
          variant="temporary"
          onClose={onCloseNav}
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          anchor="left"
          variant="persistent"
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default NavBar;
