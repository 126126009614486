import clsx from "clsx";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { PATH_APP } from "src/routes/paths";
import MyAvatar from "src/components/MyAvatar";
import React, { useRef, useState } from "react";
import PopoverMenu from "src/components/PopoverMenu";
import settings2Fill from "@iconify-icons/eva/settings-2-fill";
import sunOutline from "@iconify-icons/eva/sun-outline";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { Button, Box, Divider, MenuItem, Typography } from "@material-ui/core";
import { MIconButton } from "src/theme";
import { useTranslation } from "react-i18next";
import { useAuthController } from "src/controllers/useAuthController";
import Settings from "src/layouts/Common/Settings";
import { useSelector } from "react-redux";

const MENU_OPTIONS = [
  {
    label: "settings",
    icon: settings2Fill,
    linkTo: PATH_APP.management.root,
  },
];

const useStyles = makeStyles((theme) => ({
  btnAvatar: {
    padding: 0,
    width: 44,
    height: 44,
  },
  isSelected: {
    "&:before": {
      zIndex: 1,
      content: "''",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      position: "absolute",
      background: alpha(theme.palette.grey[900], 0.8),
    },
  },
}));

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const anchorRef = useRef(null);
  const { firstName } = useSelector((state: any) => state.user);
  const companyName = useSelector((state: any) => state.company).name;
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const [isOpenSettings, setOpenSettings] = useState(false);
  const authController = useAuthController({});
  const { t } = useTranslation();

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await authController.logoff();
      history.push("/");
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout", { variant: "error" });
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        className={clsx(classes.btnAvatar, { [classes.isSelected]: isOpen })}
      >
        <MyAvatar />
      </MIconButton>

      <PopoverMenu
        width={220}
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ my: 2, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {firstName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {companyName}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {t(`topBar.account.${option.label}`)}
          </MenuItem>
        ))}
        <MenuItem
          onClick={handleOpenSettings}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={sunOutline}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          Layout
        </MenuItem>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            {t("topBar.account.logout")}
          </Button>
        </Box>
      </PopoverMenu>
      <Settings
        open={isOpenSettings}
        handleCloseSettings={handleCloseSettings}
      />
    </>
  );
}

export default Account;
