// import {DateTime} from "luxon";

export type UserResourceReducerEmailModel = {
  address?: string;
  confirmed?: boolean;
  main?: boolean;
  status?: string;
  type?: string;
};

export type UserResourceReducerModel = {
  emails?: UserResourceReducerEmailModel[];
  firstName?: string;
  lastName?: string;
  name?: string;
  resourceType?: 'employee' | 'candidate' | 'client'
  status?: string,
};

const initialState: UserResourceReducerModel = {
  emails: undefined,
  firstName: undefined,
  lastName: undefined,
  name: undefined,
  resourceType: undefined,
  status: undefined,
};

export const userResourceReducer = (
  state: UserResourceReducerModel = initialState,
  action: any
): UserResourceReducerModel => {
  switch (action.type) {
    case "FETCH-USER-RESOURCE": {
      return {
        ...state,
        ...action.data
      };
    }
    case "FETCH-USER-EMAIL-RESOURCE": {
      return {
        ...state,
        ...action.data
      };
    }
    case "USER-RESOURCE-LOGOFF": {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

