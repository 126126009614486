import { PATH_APP } from "./paths";
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthProtect from "./AuthProtect";
import DashboardLayout from "src/layouts/DashboardLayout";

const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // ASSESSMENTS

    {
      exact: true,
      path: PATH_APP.assessments.assessments,
      component: lazy(() => import("src/views/assessments/DashboardView")),
    },
    {
      exact: true,
      path: PATH_APP.assessments.questionnaire,
      component: lazy(() => import("src/views/assessments/QuestionnaireView")),
    },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.assessments.assessments} />,
    },
    {
      exact: true,
      path: PATH_APP.assessments.root,
      component: () => <Redirect to={PATH_APP.assessments.assessments} />,
    },

    // MANAGEMENT : USER

    {
      exact: true,
      path: PATH_APP.management.myAccount,
      component: lazy(() => import("src/views/user/AccountView")),
    },
    {
      exact: true,
      path: PATH_APP.management.root,
      component: () => <Redirect to={PATH_APP.management.myAccount} />,
    },
    {
      component: () => <Redirect to="/404" />,
    },
  ],
};

export default AppRoutes;
