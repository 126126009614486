function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: "/auth",
  app: "/app",
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, "/login"),
    loginUnprotected: path(ROOTS.auth, "/login-unprotected"),
    register: path(ROOTS.auth, "/register"),
    registerUnprotected: path(ROOTS.auth, "/register-unprotected"),
    forgotPassword: path(ROOTS.auth, "/forgot-password"),
    verify: path(ROOTS.auth, "/verify"),
    resetPassword: path(ROOTS.auth, "/reset-password"),
  },
  maintenance: "/maintenance",
};

export const PATH_HOME = {
  dashboard: ROOTS.app,
};

export const PATH_APP = {
  root: ROOTS.app,
  assessments: {
    root: path(ROOTS.app, "/assessments/"),
    assessments: path(ROOTS.app, "/assessments/"),
    questionnaire: path(ROOTS.app, "/assessments/:assessmentNodeId/questionnaire/"),
  },
  management: {
    root: path(ROOTS.app, "/myaccount/"),
    myAccount: path(ROOTS.app, "/myaccount/"),
  },
};
