import clsx from "clsx";
import ThemeMode from "./ThemeMode";
import { Icon } from "@iconify/react";
import React from "react";
import ThemeDirection from "./ThemeDirection";
import closeFill from "@iconify-icons/eva/close-fill";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Drawer, Divider, Typography } from "@material-ui/core";
import { MIconButton } from "src/theme";
import { useTranslation } from "react-i18next";

const DRAWER_WIDTH = 260;

// @ts-ignore
const useStyles = makeStyles(() => ({
  root: {},
  drawer: {
    zIndex: "1999 !important",
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
}));

export type SettingsProps = {
  open: boolean;
  className?: string;
  handleCloseSettings: () => void;
};

function Settings({ className, open, handleCloseSettings }: SettingsProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, className)}>
      <Drawer
        open={open}
        anchor="right"
        onClose={handleCloseSettings}
        classes={{
          root: classes.drawer,
          paper: classes.drawerPaper,
        }}
      >
        <Box
          sx={{
            py: 2,
            pr: 1,
            pl: 2.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1">
            {t("topBar.settings.title")}
          </Typography>
          <MIconButton onClick={handleCloseSettings}>
            <Icon icon={closeFill} width={20} height={20} />
          </MIconButton>
        </Box>
        <Divider />

        <Box sx={{ p: 2.5 }}>
          <Typography variant="subtitle2" gutterBottom>
            {t("topBar.settings.mode")}
          </Typography>
          <ThemeMode />

          <Box sx={{ my: 3 }} />

          <Typography variant="subtitle2" gutterBottom>
            {t("topBar.settings.direction")}
          </Typography>
          <ThemeDirection />
        </Box>
      </Drawer>
    </div>
  );
}

export default Settings;
