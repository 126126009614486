import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { MAvatar } from 'src/theme';

export type MyAvatarProps = {
  className?: string
};

function MyAvatar({ className }: MyAvatarProps) {
  const {firstName, lastName} = useSelector((state: any) => state.user);
  const classes = useStyles();

  return (
    <MAvatar
      className={clsx(classes.root, className)}
      color="warning"
    >
      {firstName 
        ? lastName 
          ? firstName.substr(0, 1) + lastName.substr(0, 1) 
          : firstName.substr(0, 2) 
        : null
      }
    </MAvatar>
  );
}

export default MyAvatar;

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 4,
  },
}))
