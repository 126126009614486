import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const browserLanguage = () => {
  const language = navigator.language
  if (language === "en-US") return "en_us";
  if (language === "es-AR") return "es_ar";
  if (language === "pt-BR") return "pt_br";
  else return "en_us";
};

const options = {
  loadPath: "/locales/{{lng}}/translation.json",
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem("i18nextLng") || browserLanguage(),
    fallbackLng: browserLanguage(),
    debug: false,
    cleanCode: true,
    backend: options,
    react: {
      useSuspense: false,
    },
  })
  .then();

export default i18n;
