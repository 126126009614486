import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {PATH_PAGE} from 'src/routes/paths';
import {DateTime} from "luxon";

export type AuthProtectProps = {
  children?: React.ReactNode
};

function AuthProtect({children}: AuthProtectProps) {
  const {loggedIn, expiresAt} = useSelector((state: any) => state.authLoginReducer);

  if (!loggedIn || DateTime.fromISO(expiresAt) < DateTime.now()) {
    return <Redirect to={PATH_PAGE.auth.login}/>;
  }

  return <>{children}</>;
}

export default AuthProtect;
