import axios, {AxiosInstance, AxiosRequestConfig, ResponseType} from 'axios';
import moment from "moment";

export type AxiosMiddlewareConfig = {
  baseURL?: string;
  headers?: any;
  timeout?: number;
  responseType?: ResponseType;
  onUploadProgress?: (progressEvent: any) => void;
  onDownloadProgress?: (progressEvent: any) => void;
  maxContentLength?: number;
  maxRedirects?: number;
}
const createLogger: boolean = true;

export class AxiosMiddleware {
  private client: AxiosInstance;

  constructor(config: AxiosMiddlewareConfig) {
    this.client = axios.create({...config});
  }

  private static handleCatchData(data: any): Promise<any> {
    let resp = data.response;
    if (resp?.status === 401) {
      console.error("Unauthorized");
    }
    createLogger && console.debug('AxiosMiddleware: Error', resp.data);
    throw resp.data.data;
  }

  async delete(url: string, config?: AxiosRequestConfig) {
    let begin = moment(new Date());
    return await this.client.delete(url, config)
      .then((data) => {
        let end = moment(new Date());
        createLogger && console.debug('AxiosMiddleware: Delete', end.diff(begin) / 1000, {url});
        return data.data.data;
      })
      .catch((data) => {
        let end = moment(new Date());
        createLogger && console.debug('AxiosMiddleware: Delete', end.diff(begin) / 1000, {url});
        return AxiosMiddleware.handleCatchData(data);
      });
  }

  async head(url: string, config?: AxiosRequestConfig) {
    let begin = moment(new Date());
    return await this.client.head(url, config)
      .then((data) => {
        let end = moment(new Date());
        createLogger && console.debug('AxiosMiddleware: Head', end.diff(begin) / 1000, {url});
        return data.data.data;
      })
      .catch((data) => {
        let end = moment(new Date());
        createLogger && console.debug('AxiosMiddleware: Head', end.diff(begin) / 1000, {url});
        return AxiosMiddleware.handleCatchData(data);
      });
  }

  async put(url: string, data?: any, config?: AxiosRequestConfig) {
    let begin = moment(new Date());
    return await this.client.put(url, data, config)
      .then((data) => {
        let end = moment(new Date());
        createLogger && console.debug('AxiosMiddleware: Put', end.diff(begin) / 1000, {url});
        return data.data.data;
      })
      .catch((data) => {
        let end = moment(new Date());
        createLogger && console.debug('AxiosMiddleware: Put', end.diff(begin) / 1000, {url});
        return AxiosMiddleware.handleCatchData(data);
      });
  }

  async patch(url: string, data?: any, config?: AxiosRequestConfig) {
    let begin = moment(new Date());
    return await this.client.patch(url, data, config)
      .then((data) => {
        let end = moment(new Date());
        createLogger && console.debug('AxiosMiddleware: Patch', end.diff(begin) / 1000, {url});
        return data.data.data;
      })
      .catch((data) => {
        let end = moment(new Date());
        createLogger && console.debug('AxiosMiddleware: Patch', end.diff(begin) / 1000, {url});
        return AxiosMiddleware.handleCatchData(data);
      });
  }

  async get(url: string, config?: AxiosRequestConfig) {
    let begin = moment(new Date());
    return await this.client.get(url, config)
      .then((data) => {
        let end = moment(new Date());
        createLogger && console.debug('AxiosMiddleware: Get', end.diff(begin) / 1000, {url});
        return data.data.data;
      })
      .catch((data) => {
        let end = moment(new Date());
        createLogger && console.debug('AxiosMiddleware: Get', end.diff(begin) / 1000, {url});
        return AxiosMiddleware.handleCatchData(data);
      });
  }

  async post(url: string, data?: any, config?: AxiosRequestConfig) {
    let begin = moment(new Date());
    return await this.client.post(url, data, config)
      .then((data) => {
        let end = moment(new Date());
        createLogger && console.debug('AxiosMiddleware: Post', end.diff(begin) / 1000, {url}, config?.params);
        return data.data.data;
      })
      .catch((data) => {
        let end = moment(new Date());
        createLogger && console.debug('AxiosMiddleware: Post', end.diff(begin) / 1000, {url});
        return AxiosMiddleware.handleCatchData(data);
      });
  }

}
