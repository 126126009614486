import {AxiosMiddleware} from "src/apis/niki-api/middleware/AxiosMiddleware";
import {nikiAssessmentApiConfig} from "src/config";

export class ResourceApi {
  constructor(accessToken: string) {
    this._axiosMiddleware = new AxiosMiddleware({
      baseURL: nikiAssessmentApiConfig.baseURL,
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    });
  }

  private _axiosMiddleware: AxiosMiddleware;

  async resourceGet() {
    return await this._axiosMiddleware.get(`/api/resource`);
  }

  async resourceAddressGet() {
    return await this._axiosMiddleware.get(`/api/resource/address`);
  }

  async resourceAddressDelete(addressId: string) {
    return await this._axiosMiddleware.delete(`/api/resource/address/${addressId}`);
  }

  async resourceDocumentGet() {
    return await this._axiosMiddleware.get(`/api/resource/document`);
  }

  async resourceDocumentDelete(documentId: string) {
    return await this._axiosMiddleware.delete(`/api/resource/document/${documentId}`);
  }

  async resourceEmailGet() {
    return await this._axiosMiddleware.get(`/api/resource/email`);
  }

  async resourceEmailDelete(emailId: string) {
    return await this._axiosMiddleware.delete(`/api/resource/email/${emailId}`);
  }

  async resourcePhoneGet() {
    return await this._axiosMiddleware.get(`/api/resource/phone`);
  }

  async resourcePhoneDelete(phoneId: string) {
    return await this._axiosMiddleware.delete(`/api/resource/phone/${phoneId}`);
  }
}
