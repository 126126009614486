import clsx from 'clsx';
import React from 'react';
import { last } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Link, Breadcrumbs } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {}
}));

const Separator = (
  <Box
    component="span"
    sx={{
      width: 4,
      height: 4,
      borderRadius: '50%',
      bgcolor: 'text.disabled'
    }}
  />
);

export type LinkItemProps = {
  link?: any,
}

function LinkItem({ link }: LinkItemProps) {
  const { href, name, icon } = link;
  return (
    <Link
      to={href}
      key={name}
      variant="body2"
      component={RouterLink}
      sx={{
        lineHeight: 2,
        display: 'flex',
        alignItems: 'center',
        color: 'text.primary',
        '& > div': { display: 'inherit' }
      }}
    >
      {icon && (
        <Box
          sx={{
            mr: 1,
            '& svg': { width: 20, height: 20 }
          }}
        >
          {icon}
        </Box>
      )}
      {name}
    </Link>
  );
}

export type MBreadcrumbsProps = {
  links?: any,
  activeLast?: boolean,
  className?: string,
};

function MBreadcrumbs({ links, activeLast = false, className }: MBreadcrumbsProps) {
  const classes = useStyles();
  // @ts-ignore
  const currentLink = last(links).name;

  const listDefault = links.map((link: any) => <LinkItem link={link} />);
  const listActiveLast = links.map((link: any) => (
    <div key={link.name}>
      {link.name !== currentLink ? (
        <LinkItem link={link} />
      ) : (
        <Typography
          variant="body2"
          sx={{
            maxWidth: 260,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: 'text.disabled',
            textOverflow: 'ellipsis'
          }}
        >
          {currentLink}
        </Typography>
      )}
    </div>
  ));

  return (
    <Breadcrumbs
      separator={Separator}
      className={clsx(classes.root, className)}
    >
      {activeLast ? listDefault : listActiveLast}
    </Breadcrumbs>
  );
}

export default MBreadcrumbs;
