import { useDispatch } from "react-redux";
// import { DateTime } from "luxon";

export function useResourceAction() {
  const dispatch = useDispatch();

  return {
    fetchUserResource: (firstName?: string, lastName?: string, name?: string, resourceType?: 'employee' | 'candidate' | 'client', status?: string,) => {
      dispatch({
        type: "FETCH-USER-RESOURCE",
        data: {
          firstName,
          lastName,
          name,
          resourceType,
          status,
        },
      });
    },
    fetchUserEmailResource: (emails: []) => {
      dispatch({
        type: "FETCH-USER-EMAIL-RESOURCE",
        data: {
          emails,
        },
      });
    },
    logoff: () => {
      dispatch({ type: "USER-RESOURCE-LOGOFF" });
    },
  };
}
