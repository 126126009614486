export type NbAuthConfig = {
  clientId: string;
  clientSecret: string;
  baseURL: string;
  authDomain: string;
}

export const nbAuthConfig: NbAuthConfig = {
  clientId: `${process.env.REACT_APP_NBAUTH_CLIENT_ID}`,
  clientSecret: `${process.env.REACT_APP_NBAUTH_CLIENT_SECRET}`,
  baseURL: `${process.env.REACT_APP_NBAUTH_BASEURL}`,
  authDomain: `${process.env.REACT_APP_NBAUTH_AUTH_DOMAIN}`
}

export type NikiAssessmentApiConfig = {
  baseURL: string;
}

export const nikiAssessmentApiConfig: NikiAssessmentApiConfig = {
  baseURL: `${process.env.REACT_APP_NIKIASSESSMENTAPI_BASEURL}`
}

export const mapConfig = {
  apiGoogle: process.env.REACT_APP_MAP_GOOGLE,
  apiMapBox: process.env.REACT_APP_MAP_MAPBOX
};

export const cloudinaryConfig = {
  cloudinaryKey: process.env.REACT_APP_CLOUDINARY_KEY,
  cloudinaryPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
  cloudinaryUrl: process.env.REACT_APP_CLOUDINARY_URL
};

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
