import { AuthApi } from "src/apis/nbauth-api/nbAuthApi";
import { useAuthLoginAction } from "src/redux/actions/auth/authLoginAction";
import { useResourceAction } from "src/redux/actions/user/userResourceAction";
import { ResourceApi } from "src/apis/niki-api/api/ResourceApi";
import { EmailModelApiModel } from "src/apis/niki-api/model/EmailModelApiModel";
import { CompanyApi } from "src/apis/niki-api/api/CompanyApi";
import { useCompanyInfoAction } from "src/redux/actions/company/useCompanyInfoAction";

interface UseAuthControllerParams {}

export function useAuthController(props?: UseAuthControllerParams) {
  const {} = props ?? {};
  const loginAction = useAuthLoginAction();
  const resourceAction = useResourceAction();
  const companyAction = useCompanyInfoAction();
  let accessToken = ""

  return {
    login: async (username: string, password: string) => {
      
      await AuthApi.authLoginCredentials(username, password).then(
        (authData) => {
          let accessData = {
            accessToken: authData["access_token"],
            refreshToken: authData["refresh_token"],
            expiresIn: authData["expires_in"],
          };
          accessToken = accessData.accessToken;

          loginAction.login(
            accessData.accessToken,
            accessData.refreshToken,
            accessData.expiresIn,
          );
        }
      );

      const resourceApi = new ResourceApi(accessToken);

      await resourceApi.resourceEmailGet().then((response) => {
        resourceAction.fetchUserEmailResource(
          response.map((item: EmailModelApiModel) => ({
            address: item.address,
            confirmed: item.confirmed,
            main: item.main,
            status: item.status,
            type: item.type
          }),
        ));
      });

      await resourceApi.resourceGet().then((response) => {
        let firstName = response.personalInfo.firstName;
        let lastName = response.personalInfo.lastName;
        let name = lastName ? firstName + " " + lastName : firstName;
        let status = response.status ?? "";
        let resourceType: "employee" | "candidate" | "client" | undefined = undefined;
        switch (response.type) {
          case "Employee":
            resourceType = "employee";
            break;
          case "Candidate":
            resourceType = "candidate";
            break;
          case "Client":
            resourceType = "client";
            break;
        }

        resourceAction.fetchUserResource(
          firstName,
          lastName,
          name,
          resourceType,
          status,
        );
      });

      const companyApi = new CompanyApi(accessToken);

      await companyApi.companyInfoGet().then((response) => {

        companyAction.fetchUserResource(
          response.domain,
          response.name,
          response.status,
          response.type,
          response.logoUrl
        );
      });
    },

    logoff: async () => {
      loginAction.logoff();
      resourceAction.logoff();
      companyAction.logoff();
    },
  };
}
