import React from 'react';
import {Box, Hidden} from '@material-ui/core';

export type LogoProps = {
  className?: string,
  sx?: any,
};

function Logo({className, sx}: LogoProps) {
  return (
    <Box
      component="img"
      alt="logo"
      src="/static/brand/oldelval_logo_sinergia.svg"
      className={className}
      style={{width: '300px'}}
      sx={sx}
    />
  );
}

export default Logo;
